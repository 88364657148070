import { default as change4KhA7JDq3BMeta } from "/tmp/build_53392f5a/pages/account/change.vue?macro=true";
import { default as indexDtuY3ApVPxMeta } from "/tmp/build_53392f5a/pages/account/index.vue?macro=true";
import { default as loginB2L0ZWh0V3Meta } from "/tmp/build_53392f5a/pages/account/login.vue?macro=true";
import { default as _91token_93cQiLw5ZkYUMeta } from "/tmp/build_53392f5a/pages/account/password-reset/[uidb64]/[token].vue?macro=true";
import { default as completerseDBlZUP1Meta } from "/tmp/build_53392f5a/pages/account/password-reset/complete.vue?macro=true";
import { default as donemfT3MYbc7gMeta } from "/tmp/build_53392f5a/pages/account/password-reset/done.vue?macro=true";
import { default as indexUrv4efEF5MMeta } from "/tmp/build_53392f5a/pages/account/password-reset/index.vue?macro=true";
import { default as password_45resetmCwAIUVSweMeta } from "/tmp/build_53392f5a/pages/account/password-reset.vue?macro=true";
import { default as passwordyt7r138gmbMeta } from "/tmp/build_53392f5a/pages/account/password.vue?macro=true";
import { default as registerNC2pArx3RqMeta } from "/tmp/build_53392f5a/pages/account/register.vue?macro=true";
import { default as cookie_45policyDxIJi9Rim3Meta } from "/tmp/build_53392f5a/pages/docs/cookie-policy.vue?macro=true";
import { default as privacy_45policyp2K0fIoVcLMeta } from "/tmp/build_53392f5a/pages/docs/privacy-policy.vue?macro=true";
import { default as terms_45of_45use7Bd3a1JgarMeta } from "/tmp/build_53392f5a/pages/docs/terms-of-use.vue?macro=true";
import { default as _91slug_935Ke9xyla8QMeta } from "/tmp/build_53392f5a/pages/events/[slug].vue?macro=true";
import { default as indexOsM6xoQt6rMeta } from "/tmp/build_53392f5a/pages/events/index.vue?macro=true";
import { default as index6wadHIV8y9Meta } from "/tmp/build_53392f5a/pages/index.vue?macro=true";
import { default as _91id_934rRaFUgIvVMeta } from "/tmp/build_53392f5a/pages/orders/[id].vue?macro=true";
import { default as indexeKlztPeW9dMeta } from "/tmp/build_53392f5a/pages/orders/index.vue?macro=true";
import { default as indexyWCyb4iC06Meta } from "/tmp/build_53392f5a/pages/purchase/index.vue?macro=true";
export default [
  {
    name: "account-change",
    path: "/account/change",
    meta: change4KhA7JDq3BMeta || {},
    component: () => import("/tmp/build_53392f5a/pages/account/change.vue").then(m => m.default || m)
  },
  {
    name: "account",
    path: "/account",
    meta: indexDtuY3ApVPxMeta || {},
    component: () => import("/tmp/build_53392f5a/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-login",
    path: "/account/login",
    meta: loginB2L0ZWh0V3Meta || {},
    component: () => import("/tmp/build_53392f5a/pages/account/login.vue").then(m => m.default || m)
  },
  {
    name: password_45resetmCwAIUVSweMeta?.name,
    path: "/account/password-reset",
    meta: password_45resetmCwAIUVSweMeta || {},
    component: () => import("/tmp/build_53392f5a/pages/account/password-reset.vue").then(m => m.default || m),
    children: [
  {
    name: "account-password-reset-uidb64-token",
    path: ":uidb64()/:token()",
    component: () => import("/tmp/build_53392f5a/pages/account/password-reset/[uidb64]/[token].vue").then(m => m.default || m)
  },
  {
    name: "account-password-reset-complete",
    path: "complete",
    component: () => import("/tmp/build_53392f5a/pages/account/password-reset/complete.vue").then(m => m.default || m)
  },
  {
    name: "account-password-reset-done",
    path: "done",
    component: () => import("/tmp/build_53392f5a/pages/account/password-reset/done.vue").then(m => m.default || m)
  },
  {
    name: "account-password-reset",
    path: "",
    component: () => import("/tmp/build_53392f5a/pages/account/password-reset/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "account-password",
    path: "/account/password",
    meta: passwordyt7r138gmbMeta || {},
    component: () => import("/tmp/build_53392f5a/pages/account/password.vue").then(m => m.default || m)
  },
  {
    name: "account-register",
    path: "/account/register",
    meta: registerNC2pArx3RqMeta || {},
    component: () => import("/tmp/build_53392f5a/pages/account/register.vue").then(m => m.default || m)
  },
  {
    name: "docs-cookie-policy",
    path: "/docs/cookie-policy",
    component: () => import("/tmp/build_53392f5a/pages/docs/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: "docs-privacy-policy",
    path: "/docs/privacy-policy",
    component: () => import("/tmp/build_53392f5a/pages/docs/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "docs-terms-of-use",
    path: "/docs/terms-of-use",
    component: () => import("/tmp/build_53392f5a/pages/docs/terms-of-use.vue").then(m => m.default || m)
  },
  {
    name: "events-slug",
    path: "/events/:slug()",
    component: () => import("/tmp/build_53392f5a/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: "events",
    path: "/events",
    component: () => import("/tmp/build_53392f5a/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/tmp/build_53392f5a/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "orders-id",
    path: "/orders/:id()",
    component: () => import("/tmp/build_53392f5a/pages/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: "orders",
    path: "/orders",
    meta: indexeKlztPeW9dMeta || {},
    component: () => import("/tmp/build_53392f5a/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "purchase",
    path: "/purchase",
    component: () => import("/tmp/build_53392f5a/pages/purchase/index.vue").then(m => m.default || m)
  }
]