import type {DataTableFilterMeta, DataTablePageEvent} from 'primevue/datatable'
import type {DataViewPageEvent} from 'primevue/dataview'
import type {ToastServiceMethods} from 'primevue/toastservice'

export const errorsToToast = (error?: BadRequest, toast?: ToastServiceMethods) => {
  if (!toast) return

  if (!error || !error.data) {
    toast.add({
      detail: 'Ha ocurrido un error desconocido.',
      life: 3000,
      severity: 'error',
      summary: 'Error',
    })
    return
  }

  Object.keys(error.data).forEach(key => {
    if (key === 'detail') {
      toast.add({
        detail: error.data.detail,
        life: 3000,
        severity: 'error',
        summary: 'Error',
      })
    } else {
      const messages = error.data[key]
      messages.forEach((message: string) => {
        toast.add({
          detail: message,
          life: 3000,
          severity: 'error',
          summary: 'Error',
        })
      })
    }
  })

}

export const getFiltersValue = (filters: DataTableFilterMeta) => {
  const newFilters: Record<string, string|undefined> = {}

  Object.keys(filters).forEach((key: string) => {
    const filter = filters[key]

    if (typeof filter === 'string') {
      newFilters[key] = filter
    } else if ('value' in filter) {
      let value = filter.value
      if (value && Object.hasOwn(value, 'id')) value = value.id
      newFilters[key] = value
    }
  })

  return newFilters
}

export const getPagination = (event: DataTablePageEvent|DataViewPageEvent): PaginationInput => ({
  page: event.page + 1,
  per_page: event.rows
})

export const setupHeaders = async (headers: HeadersInit) => {
  const { company } = useCompany()

  if (company.value) {
    if (Array.isArray(headers)) {
      headers.push(['X-Company-Id', String(company.value.id)])
    } else if (headers instanceof Headers) {
      headers.set('X-Company-Id', String(company.value.id))
    } else {
      headers["X-Company-Id"] = String(company.value.id)
    }
  }

  return headers
}
